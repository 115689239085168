import { Grid, Typography } from '@material-ui/core';
import { Field, useFormikContext } from 'formik';
import {
  RuleForm as FormFields,
  RULE_TYPES_ENUM,
} from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import SwitchFormik from 'app/components/form/formik/SwitchFormik';
import BooleanRuleDataFormComponent from 'features/settings/integrityManagement/rules/BooleanRuleDataFormComponent';
import NumericRuleDataFormComponent from 'features/settings/integrityManagement/rules/NumericRuleDataFormComponent';
import GeographyRuleDataFormComponent from 'features/settings/integrityManagement/rules/GeographyRuleDataFormComponent';
import TimeRuleDataFormComponent from 'features/settings/integrityManagement/rules/TimeRuleDataFormComponent';
import React from 'react';

const DefaultRuleValueWrapper = () => {
  const { values } = useFormikContext();
  const selectedMaintenanceType = values[FormFields.RULE_TYPE];
  const defaultValueCheck = values[FormFields.DEFAULT_VALUE_CHECKED];

  const ruleTypeComponents = React.useMemo(
    () => ({
      [RULE_TYPES_ENUM.RULE_BOOL]: {
        CustomComponent: BooleanRuleDataFormComponent,
      },
      [RULE_TYPES_ENUM.RULE_DOUBLE]: {
        CustomComponent: NumericRuleDataFormComponent,
      },
      [RULE_TYPES_ENUM.RULE_GEOGRAPHY]: {
        CustomComponent: GeographyRuleDataFormComponent,
      },
      [RULE_TYPES_ENUM.RULE_TIME]: {
        CustomComponent: TimeRuleDataFormComponent,
      },
    }),
    [],
  );

  const SelectedComponent = ruleTypeComponents[selectedMaintenanceType] || {
    CustomComponent: () => null,
  };
  return (
    <>
      <Grid container alignItems="center">
        <Field name={FormFields.DEFAULT_VALUE_CHECKED}>
          {({ form, ...formik }) => {
            formik.field.onChange = (e) => {
              form.setFieldValue(
                FormFields.DEFAULT_VALUE_CHECKED,
                Number(e.target.checked),
              );
            };
            return (
              <>
                <SwitchFormik form={form} {...formik} />
                <Typography>Default Value</Typography>
              </>
            );
          }}
        </Field>
      </Grid>
      {defaultValueCheck ? (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <SelectedComponent.CustomComponent />
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

export default DefaultRuleValueWrapper;
