import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import { Button } from 'app/components/withTooltip';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';

const RulesActions = ({ classes, toggleDrawer }) => {
  return (
    <Grid
      xs
      item
      container
      spacing={2}
      justifyContent="flex-end"
      alignItems="center"
    >
      <Grid item>
        <Button
          size="medium"
          variant="contained"
          onClick={() => toggleDrawer()}
        >
          <AddIcon fontSize="small" className={classes.leftIcon} />
          New Rule
        </Button>
      </Grid>
      <Grid item>
        <Button
          size="medium"
          color="primary"
          variant="contained"
          disabled
          // onClick={() => copyTask(projectId, task.get('id'))}
        >
          <RefreshIcon fontSize="small" className={classes.leftIcon} />
          Update Wells
        </Button>
      </Grid>
    </Grid>
  );
};

const styles = (theme) => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

export default withStyles(styles)(RulesActions);
