import { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { BasePageHeader, BasePage } from 'altus-ui-components';
import Alert from '@material-ui/lab/Alert';
import withStyles from '@material-ui/styles/withStyles';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import { getIntegrityManagementRulesFromState } from 'features/settings/integrityManagement/helpers/integrityManagement.selectors';
import { INTEGRITY_MANAGEMENT_ACTIONS } from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import {
  deleteIntegrityManagementRule,
  loadIntegrityManagementRules,
} from 'features/settings/integrityManagement/helpers/integrityManagement.actions';
import RulesTable from 'features/settings/integrityManagement/rules/RulesTable';
import { Grid } from '@material-ui/core';
import RulesActions from 'features/settings/integrityManagement/rules/RulesActions';
import { useToggle } from 'altus-hooks';
import AddRuleDrawer from 'features/settings/integrityManagement/rules/AddRuleDrawer';

const IntegrityManagementContainer = ({
  dataState,
  rules,
  dispatchLoadIntegrityManagementRules,
  dispatchDeleteIntegrityManagementRule,
  classes,
  Icon,
  breadcrumb,
}) => {
  const [isOpen, toggle] = useToggle();

  useEffect(() => {
    dispatchLoadIntegrityManagementRules();
  }, [dispatchLoadIntegrityManagementRules]);

  const deleteRule = (ruleId) => {
    dispatchDeleteIntegrityManagementRule(ruleId);
  };

  return (
    <>
      <BasePageHeader title={breadcrumb} Icon={Icon} />
      <BasePage dataState={dataState}>
        <Alert className={classes.alert} severity="info">
          After all your edits, make sure to click on the 'Update Wells' button
          so they are reflected across all wells.
        </Alert>
        <Grid item container spacing={2} className={classes.tableContainer}>
          <RulesActions toggleDrawer={toggle} />
          <RulesTable rules={rules} deleteRule={deleteRule} />
          <AddRuleDrawer isOpen={isOpen} toggleDrawer={toggle} />
        </Grid>
      </BasePage>
    </>
  );
};

const styles = (theme) => ({
  tableContainer: {
    paddingTop: theme.spacing(2),
  },
  alert: {
    backgroundColor: theme.palette.secondary.darkGrey,
  },
});

export default compose(
  connect(
    (state) => ({
      rules: getIntegrityManagementRulesFromState(state),
      dataState: getSummarizedDataStateFromState(
        state,
        INTEGRITY_MANAGEMENT_ACTIONS.GET_ALL_INTEGRITY_MANAGEMENT_RULES,
        INTEGRITY_MANAGEMENT_ACTIONS.DELETE_INTEGRITY_MANAGEMENT_RULE,
      ),
    }),
    {
      dispatchLoadIntegrityManagementRules: loadIntegrityManagementRules,
      dispatchDeleteIntegrityManagementRule: deleteIntegrityManagementRule,
    },
  ),
  withStyles(styles),
)(IntegrityManagementContainer);
