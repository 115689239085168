import moment from 'moment';
import { Map, OrderedMap, List } from 'immutable';

import { CableType } from 'features/projects/tool/tool.constants';
import {
  EQIPMENT_CATEGORY_IDS,
  EQUIPMENT_CATEGORY_FROMDTO,
} from 'features/equipment/equipment.constants';

import { ThirdPartyLabel } from 'features/projects/tool/tool.constants';

const dropdownListItemFromDto = ({ ...dropdownListItem }) =>
  Map({
    ...dropdownListItem,
  });

const toolCategoryStringToId = (toolCategory) =>
  ({
    [EQUIPMENT_CATEGORY_FROMDTO.MechanicalWirelineEquipment]:
      EQIPMENT_CATEGORY_IDS.MWL_TOOLS,
    [EQUIPMENT_CATEGORY_FROMDTO.ElectricalWirelineEquipment]:
      EQIPMENT_CATEGORY_IDS.EWL_TOOLS,
    [EQUIPMENT_CATEGORY_FROMDTO.ThirdPartyDownholeEquipment]:
      EQIPMENT_CATEGORY_IDS.THIRD_PARTY_TOOLS,
  }[toolCategory]);

export const toolFromDto = ({
  toolId,
  maximumPullForce,
  swl,
  toolAssemblyTools,
  toolCategory,
  ...tool
}) =>
  Map({
    id: toolId,
    maxPullForce: maximumPullForce,
    safeWorkingLoad: swl,
    toolAssemblyTools: List(toolAssemblyTools).map(toolFromDto),
    toolCategoryId: toolCategoryStringToId(toolCategory),
    ...tool,
  });

export const toolsFromDto = (tools) =>
  OrderedMap(tools.map((tool) => [tool.toolId.toString(), toolFromDto(tool)]));

export const toolImageFromDto = ({
  toolImageId,
  created,
  modified,
  originalFileName,
  blobStoreUri,
  ...toolImage
}) =>
  Map({
    id: toolImageId,
    created: created ? moment(created) : undefined,
    modified: modified ? moment(modified) : undefined,
    fullName: originalFileName,
    imageUrl: blobStoreUri,
    ...toolImage,
  });

export const toolImportFromDto = ({ importedTools, importErrors }) =>
  Map({
    tools: toolsFromDto(importedTools),
    errors: List(importErrors),
  });

export const toolFilterValuesFromDto = (list) =>
  OrderedMap(
    list?.map((listItem) => [listItem.id, dropdownListItemFromDto(listItem)]),
  );

export const cableTypeToString = (cableType) =>
  ({
    [CableType.MONO]: 'Mono',
    [CableType.SWAB]: 'Swab',
    [CableType.SLICKLINE]: 'Slickline',
    [CableType.MULTICONDUCTOR]: 'Multi-Conductor',
    [CableType.COAX]: 'Coax',
    [CableType.ELINE]: 'E-line',
  }[cableType]);

export const cableFromDto = ({
  type,
  cableId,
  stretchCoefficient,
  manufacturerPartNo,
  ...cable
}) =>
  Map({
    type,
    id: cableId,
    partNo: manufacturerPartNo,
    stretch: stretchCoefficient,
    typeName: cableTypeToString(type),
    ...cable,
  });

export const toolStringDto = ({
  name,
  toolStringId,
  toolStringItems,
  imageUrl,
  totalLength,
  totalWeight,
  maxOD,
  ...template
}) => {
  const tool = Map({
    name,
    id: toolStringId,
    toolStringItems: List(toolStringItems).map(toolStringItemDto),
    imageUrl: imageUrl,
    length: totalLength,
    weight: totalWeight,
    outerDiameter: maxOD,
    verifiedData: verifyToolstringData(toolStringItems),
    ...template,
  });

  return tool;
};

const verifyToolstringData = (toolStringItems) => {
  let dataVerified = true;

  for (const tool of toolStringItems) {
    if (tool.verifiedData === false) {
      dataVerified = false;
      break;
    }
  }

  return dataVerified;
};

export const toolStringItemDto = ({
  name,
  toolId,
  toolStringItems,
  imageUrl,
  length,
  width,
  toolAssemblyTools,
  dataVerified,
  attachedTools,
  toolstringItemId,
  ...template
}) => {
  const dataVerifiedHasValue = dataVerified ? true : false;
  const tool = Map({
    name,
    id: toolId,
    toolStringItems: List(toolStringItems).map(toolStringItemDto),
    imageUrl: imageUrl,
    dataVerified,
    length: length,
    width: width,
    verifiedData: toolAssemblyTools
      ? verifyToolStringItemData(toolAssemblyTools)
      : dataVerifiedHasValue,
    toolAssemblyTools,
    toolstringItemId,
    attachedTools: attachedTools
      ? List(attachedTools).map((a) =>
          toolStringItemDto(a)
            .set('accumulatedLength', undefined)
            .set('attachmentOwnerId', toolstringItemId),
        )
      : undefined,
    ...template,
  });

  return tool;
};

const verifyToolStringItemData = (toolAssemblyTools) => {
  if (toolAssemblyTools && toolAssemblyTools.length > 0) {
    return verifyAssemblyData(toolAssemblyTools);
  }
};

export const cablesFromSearchResult = (cables) =>
  Map(cables.map((cable) => [cable.cableId.toString(), cableFromDto(cable)]));

export const bhaTemplatesFromSearchResult = (templates) =>
  Map(
    templates.map((template) => [
      template.toolStringId.toString(),
      toolStringDto(template),
    ]),
  );

export const surfaceEquipmentItemDto = ({
  equipmentId,
  equipmentGroupId,
  equipmentGroup,
  m3ItemNumber,
  name,
  description,
  length_mm,
  width_mm,
  height_mm,
  weight_kg,
  equipmentImage,
  externalId,
  ...equipment
}) =>
  Map({
    equipmentId: equipmentId,
    equipmentGroupId: equipmentGroupId,
    equipmentGroup: equipmentGroup ? equipmentGroupDto(equipmentGroup) : {},
    m3ItemNumber: m3ItemNumber,
    name: name,
    description: description,
    length: length_mm,
    width: width_mm,
    height: height_mm,
    weight: weight_kg,
    equipmentImage: equipmentImage ? equipmentImageDto(equipmentImage) : {},
    externalId: externalId,
    ...equipment,
  });

export const equipmentGroupDto = ({
  equipmentGroupId,
  name,
  equipmentImage,
  equipmentImageUrl,
  externalId,
}) =>
  Map({
    equipmentGroupId: equipmentGroupId,
    name: name,
    equipmentImage: equipmentImage ? equipmentImageDto(equipmentImage) : {},
    equipmentImageUrl: equipmentImageUrl,
    externalId: externalId,
  });

export const equipmentImageDto = ({
  equipmentImageId,
  externalId,
  name,
  extension,
  originalFileName,
  blobStoreUri,
  created,
  modified,
}) =>
  Map({
    equipmentImageId: equipmentImageId,
    externalId: externalId,
    name: name,
    extension: extension,
    originalFileName: originalFileName,
    imageUrl: blobStoreUri,
    created: created,
    modified: modified,
  });

export const toolAssembliesFromDto = (tools) =>
  OrderedMap(
    tools.map((tool) => [tool.toolId.toString(), toolAssemblyFromDto(tool)]),
  );

const toolAssemblyFromDto = ({
  toolAssemblyTools,
  toolId,
  maximumPullForce,
  swl,
  toolCategory,
  ...toolAssembly
}) =>
  Map({
    verifiedData: verifyAssemblyData(toolAssemblyTools),
    id: toolId,
    maxPullForce: maximumPullForce,
    safeWorkingLoad: swl,
    toolAssemblyTools: List(toolAssemblyTools).map(toolFromDto),
    toolCategoryId: toolCategoryStringToId(toolCategory),
    ...toolAssembly,
  });

const verifyAssemblyData = (toolAssemblyTools) => {
  let dataVerified = true;

  for (const tool of toolAssemblyTools) {
    if (tool.m3ItemNumber !== ThirdPartyLabel && tool.dataVerified === null) {
      dataVerified = false;
      break;
    }
  }
  return dataVerified;
};

const toolMappers = {
  Tool: {
    from: toolFromDto,
  },
  ToolImage: {
    from: toolImageFromDto,
  },
  ToolSearchFilter: {
    from: toolFilterValuesFromDto,
  },
  ToolSearch: {
    from: toolsFromDto,
  },
  ToolImport: {
    from: toolImportFromDto,
  },
  Cable: {
    from: cableFromDto,
  },
  bhaTemplate: {
    from: toolStringDto,
  },
  BhaTemplatesSearch: {
    from: bhaTemplatesFromSearchResult,
  },
  CableSearch: {
    from: cablesFromSearchResult,
  },
  SurfaceEquipment: {
    from: surfaceEquipmentItemDto,
  },
  ToolAssemblies: {
    from: toolAssembliesFromDto,
  },
};

export default toolMappers;
