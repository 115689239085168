import { addNotification } from 'altus-redux-middlewares';

import {
  EQIPMENT_CATEGORY_IDS,
  EQUIPMENT_ACTIONS,
  MODAL,
  ASSET_HISTORY_TEST_TYPE,
  ASSET_HISTORY_EVENT_TYPES_ITEM,
  ASSET_HISTORY_MAINTENANCE_TYPE,
  ASSET_HISTORY_EQUIPMENT_TYPE,
} from 'features/equipment/equipment.constants';
import { NOTIFICATION_VARIANTS } from 'app/app.constants';
import toolService from 'services/tool.service';
import { toggleModal } from 'app/app.actions';
import equipmentService from 'features/equipment/equipment.service';
import { TOOL_ACTIONS } from 'features/projects/tool/tool.constants';
import { M3SearchType } from 'app/app.constants';

const onLoadGeneric = (getAllToolStringsFunc) => (categoryId) => (dispatch) => {
  dispatch(getToolGroups(categoryId));
  dispatch(getToolSuppliers());
  dispatch(getCables());
  getAllToolStringsFunc();

  const payload = dispatch(getTools(categoryId));

  dispatch({
    type: EQUIPMENT_ACTIONS.PAGE_LOADED,
    payload: payload,
  });
};

export const onLoadWithoutToolstrings = (categoryId) => (dispatch) => {
  const onLoadFunc = onLoadGeneric(() => {});
  onLoadFunc(categoryId)(dispatch);
};

export const onLoad = (categoryId) => (dispatch) => {
  const onLoadFunc = onLoadGeneric(() => dispatch(getAllToolStrings()));
  onLoadFunc(categoryId)(dispatch);
};

export const onLoadToolAssemblies = (categoryId) => (dispatch) => {
  dispatch(getToolGroups(categoryId));
  dispatch(getToolSuppliers());
  dispatch(getCables());

  const payload = dispatch(getToolAssemblies(categoryId));
  dispatch({
    type: EQUIPMENT_ACTIONS.PAGE_LOADED,
    payload: payload,
  });
};

export const onLoadAssetHistory =
  (externalToolId, equipmentType, serialNumber) => (dispatch) => {
    dispatch(
      getEquipmentAssetHistory(externalToolId, equipmentType, serialNumber),
    );

    if (equipmentType === ASSET_HISTORY_EQUIPMENT_TYPE.CABLE) {
      dispatch(getCableTestDetails(externalToolId, serialNumber));
    }
  };

export const getToolGroups = (categoryId) => (dispatch) => {
  dispatch({
    type: EQUIPMENT_ACTIONS.TOOL_GROUPS_LOADED,
    payload: () => toolService.getToolGroupsByCategory(categoryId),
  });
};

export const getToolProjectHistoryByToolId = (toolId) => (dispatch) => {
  dispatch({
    type: TOOL_ACTIONS.RECEIVE_TOOL_PROJECT_HISTORY,
    payload: () => toolService.getToolProjectHistoryByToolId(toolId),
  });
};

export const getTools = (categoryId) => (dispatch) => {
  const filter = {
    categoryId,
  };

  dispatch({
    type: EQUIPMENT_ACTIONS.TOOLS_LOADED,
    payload: () => toolService.searchTools(filter),
  });
};

export const getEquipmentByCategories = (taskId) => (dispatch) => {
  dispatch({
    type: EQUIPMENT_ACTIONS.SURFACE_EQUIPMENT_BY_CATEGORIES,
    payload: () => equipmentService.getEquipmentByCategories(taskId),
  });
};

export const getToolAssemblies = (categoryId) => (dispatch) => {
  const filter = {
    categoryId,
  };

  dispatch({
    type: EQUIPMENT_ACTIONS.TOOL_ASSEMBLIES_LOADED,
    payload: () => toolService.searchTools(filter),
  });
};

export const getToolSuppliers = () => (dispatch) => {
  dispatch({
    type: EQUIPMENT_ACTIONS.SUPPLIERS_LOADED,
    payload: () => toolService.getToolSuppliers(),
  });
};

export const getCables = () => (dispatch) => {
  dispatch({
    type: EQUIPMENT_ACTIONS.CABLES_LOADED,
    payload: () => toolService.getCables(),
  });
};

export const getCablesByProject = (projectId) => (dispatch) => {
  if (projectId) {
    return dispatch({
      type: EQUIPMENT_ACTIONS.CABLES_LOADED_BY_PROJECTID,
      payload: () => toolService.getCablesByProject(projectId),
    });
  }
  return dispatch({
    type: EQUIPMENT_ACTIONS.CABLES_LOADED,
    payload: () => toolService.getCables(),
  });
};

export const getCable = (cableId) => (dispatch) => {
  dispatch({
    type: EQUIPMENT_ACTIONS.LOAD_CABLE,
    payload: () => toolService.getCable(cableId),
  });
};

export const searchCables = (filter) => (dispatch) => {
  dispatch({
    type: EQUIPMENT_ACTIONS.CABLES_LOADED,
    payload: () => toolService.searchCables(filter),
  });
};

export const searchTools = (filter) => (dispatch) => {
  dispatch({
    type: EQUIPMENT_ACTIONS.TOOLS_LOADED,
    payload: toolService.searchTools(filter),
  });
};

export const searchToolAssemblies = (filter) => (dispatch) => {
  dispatch({
    type: EQUIPMENT_ACTIONS.TOOL_ASSEMBLIES_LOADED,
    payload: toolService.searchTools(filter),
  });
};

export const searchBhas = (filter) => (dispatch) => {
  dispatch({
    type: EQUIPMENT_ACTIONS.BHA_TEMPLATES_LOADED,
    payload: toolService.searchBhas(filter),
  });
};

export const searchSurfaceEquipment = (filter) => (dispatch) => {
  dispatch({
    type: EQUIPMENT_ACTIONS.SURFACE_EQUIPMENT_LOADED,
    payload: equipmentService.searchSurfaceEquipment(filter),
  });
};

export const onUnload = () => (dispatch) => {
  dispatch({ type: EQUIPMENT_ACTIONS.PAGE_UNLOADED });
};

export const onLoadAddToolsModal = () => (dispatch) => {
  dispatch({
    type: EQUIPMENT_ACTIONS.TOOL_CONNECTORS_LOADED,
    payload: toolService.getToolConnectors(),
  });
};

export const createTool = (tool) => (dispatch) => {
  dispatch({
    type: EQUIPMENT_ACTIONS.CREATE_TOOL,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Creating tool...',
      [NOTIFICATION_VARIANTS.SUCCESS]: 'Tool successfully created!',
    },
    confirmationDialog: {
      confirmButtonText: 'Save',
      title: 'Confirm tool saving',
      description: 'Are you sure you want to save this tool?',
    },
    payload: () =>
      toolService.createTool(tool).then(() => {
        dispatch(toggleModal({ modalId: MODAL.ADD_TOOL }));
        dispatch(toggleModal({ modalId: MODAL.M3_TOOL }));
      }),
  });
};

export const receiveToolCreated = (createdTool) => ({
  payload: createdTool,
  type: EQUIPMENT_ACTIONS.RECEIVE_CREATED_TOOL,
});

export const uploadToolImage = (file) => (dispatch) => {
  const payload = toolService.uploadToolImage(file);

  dispatch({
    type: EQUIPMENT_ACTIONS.UPLOAD_IMAGE,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Uploading...',
      [NOTIFICATION_VARIANTS.SUCCESS]: 'The image was successfully uploaded',
      [NOTIFICATION_VARIANTS.ERROR]: 'Failed to upload image',
    },
    payload,
  });

  return payload;
};

export const deleteToolImage = (toolImageId) => (dispatch) => {
  const payload = toolService.deleteToolImage(toolImageId);

  dispatch({
    type: EQUIPMENT_ACTIONS.DELETE_IMAGE,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Deleting image...',
      [NOTIFICATION_VARIANTS.SUCCESS]: 'Image successfully deleted!',
    },
    payload,
  });

  return payload;
};

export const getAllToolStrings = () => (dispatch) => {
  dispatch({
    type: EQUIPMENT_ACTIONS.BHA_TEMPLATES_LOADED,
    payload: () => toolService.getAllToolStrings(),
  });
};

export const searchM3Items = (textSearch) => {
  const search = { textSearch: textSearch, searchType: M3SearchType.ITEMS };
  const payload = equipmentService.searchM3Items(search);
  // #9316 Not able to add serial and item number for cable spooling test : This dispatch causes modal to be rerendered
  // dispatch({
  //   type: EQUIPMENT_ACTIONS.M3_ITEM_SEARCH,
  //   payload,
  // });

  return payload;
};

export const searchM3ItemsWithDispatch = (textSearch) => (dispatch) => {
  const search = { textSearch: textSearch, searchType: M3SearchType.ITEMS };
  const payload = equipmentService.searchM3Items(search);

  dispatch({
    type: EQUIPMENT_ACTIONS.M3_ITEM_SEARCH,
    payload,
  });

  return payload;
};

export const findM3ItemsBySerialNumber = (m3itemNumber, textSearch) => {
  const search = {
    m3ItemNumber: m3itemNumber,
    serialNumber: textSearch,
    searchType: M3SearchType.SERIALNUMBERS,
  };
  const payload = equipmentService.findM3ItemsBySerialNumber(search);

  // #9316 Not able to add serial and item number for cable spooling test : This dispatch causes modal to be rerendered
  // dispatch({
  //   type: EQUIPMENT_ACTIONS.M3_FIND_ITEMS_BY_SERIAL_NUMBER,
  //   payload,
  // });

  return payload;
};

export const findM3ItemsBySerialNumberAndStatus = (
  m3itemNumber,
  textSearch,
) => {
  const search = {
    m3ItemNumber: m3itemNumber,
    serialNumber: textSearch,
    searchType: M3SearchType.SERIALNUMBERS,
    getByStatus: true,
  };
  const payload = equipmentService.findM3ItemsBySerialNumber(search);

  return payload;
};

export const updateTool = (tool, activeModal) => (dispatch) => {
  dispatch({
    type: EQUIPMENT_ACTIONS.UPDATE_TOOL,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Updating tool',
      [NOTIFICATION_VARIANTS.SUCCESS]: 'Tool successfully updated!',
    },
    payload: () =>
      toolService.updateTool(tool).then(() => {
        dispatch(toggleModal({ modalId: activeModal }));
        window.location.reload();
      }),
  });
};

export const onLoadSurfaceEquipment = () => (dispatch) => {
  dispatch(onLoadSurfaceEquipmentsGroupsModal());
  dispatch({
    type: EQUIPMENT_ACTIONS.SURFACE_EQUIPMENT_LOADED,
    payload: () => equipmentService.getAllSurfaceEquipment(),
  });
};

export const onSearchSurfaceEquipmentByGroup = (groupId) => (dispatch) => {
  dispatch({
    type: EQUIPMENT_ACTIONS.SURFACE_EQUIPMENT_LOADED,
    payload: () => equipmentService.searchSurfaceEquipmentByGroup(groupId),
  });
};

export const createSurfaceEquipment = (tool) => (dispatch) => {
  dispatch({
    type: EQUIPMENT_ACTIONS.CREATE_SURFACE_EQUIPMENT,
    notification: {
      [NOTIFICATION_VARIANTS.ERROR]: 'Error creating surface equipment.',
      [NOTIFICATION_VARIANTS.INFO]: 'Creating surface equipment...',
      [NOTIFICATION_VARIANTS.SUCCESS]:
        'Surface equipment successfully created!',
    },
    confirmationDialog: {
      confirmButtonText: 'Save',
      title: 'Confirm surface equipment saving',
      description: 'Are you sure you want to save this?',
    },
    payload: () =>
      equipmentService.createSurfaceEquipment(tool).then(() => {
        dispatch(toggleModal({ modalId: MODAL.ADD_SURFACE_EQUIPMENT }));
        dispatch(onLoadSurfaceEquipment());
      }),
  });
};

export const onLoadSurfaceEquipmentsGroupsModal = () => (dispatch) => {
  dispatch({
    type: EQUIPMENT_ACTIONS.RECEIVE_SURFACE_EQUIPMENT_GROUPS,
    payload: equipmentService.getAllSurfaceEquipmentGroups(),
  });
};

export const uploadSurfaceEquipmentImage = (file) => (dispatch) => {
  const payload = equipmentService.uploadSurfaceEquipmentImage(file);

  dispatch({
    type: EQUIPMENT_ACTIONS.SURFACE_EQUIPMENT_UPLOAD_IMAGE,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Uploading...',
      [NOTIFICATION_VARIANTS.SUCCESS]: 'The image was successfully uploaded',
      [NOTIFICATION_VARIANTS.ERROR]: 'Failed to upload image',
    },
    payload,
  });

  return payload;
};

export const deleteSurfaceEquipmentImage = (toolImageId) => (dispatch) => {
  const payload = equipmentService.deleteSurfaceEquipmentImage(toolImageId);

  dispatch({
    type: EQUIPMENT_ACTIONS.DELETE_SURFACE_EQUIPMENT_IMAGE,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Deleting image...',
      [NOTIFICATION_VARIANTS.SUCCESS]: 'Image successfully deleted!',
    },
    payload,
  });

  return payload;
};

export const saveToolAssembly = (toolAssemblyToSave) => (dispatch) => {
  dispatch({
    type: EQUIPMENT_ACTIONS.SAVE_TOOL_ASSEMBLY,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Saving tool assembly...',
      [NOTIFICATION_VARIANTS.SUCCESS]: 'Tool assembly saved!',
    },
    payload: () =>
      toolService.saveToolAssembly(toolAssemblyToSave).then(() => {
        dispatch(getTools(EQIPMENT_CATEGORY_IDS.TOOL_ASSEMBLIES));
        dispatch(toggleModal({ modalId: MODAL.ADD_TOOL_ASSEMBLY }));
      }),
  });
};

export const getToolAssembly = (toolId) => (dispatch) => {
  const payload = toolService.getToolAssembly(toolId);
  dispatch({
    type: EQUIPMENT_ACTIONS.GET_TOOL_ASSEMBLY,
    payload: payload,
  });

  return payload;
};

export const updateToolAssembly = (toolAssemblyToSave) => (dispatch) => {
  dispatch({
    type: EQUIPMENT_ACTIONS.SAVE_TOOL_ASSEMBLY,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Saving tool assembly...',
      [NOTIFICATION_VARIANTS.SUCCESS]: 'Tool assembly saved!',
    },
    payload: () =>
      toolService.updateToolAssembly(toolAssemblyToSave).then(() => {
        dispatch(getTools(EQIPMENT_CATEGORY_IDS.TOOL_ASSEMBLIES));
        dispatch(toggleModal({ modalId: MODAL.EDIT_TOOL_ASSEMBLY }));
      }),
  });
};

export const saveBhaTemplate = (bhaTemplateToSave) => (dispatch) => {
  dispatch({
    type: EQUIPMENT_ACTIONS.SAVE_BHA_TEMPLATE,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Saving template...',
      [NOTIFICATION_VARIANTS.SUCCESS]: 'Template saved!',
    },
    payload: () => toolService.saveBhaFromClient(bhaTemplateToSave),
  });
};

export const uploadTool3DObject = (file) => (dispatch) => {
  const payload = toolService.uploadTool3DObject(file);

  dispatch({
    type: EQUIPMENT_ACTIONS.UPLOAD_3D_OBJECT,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Uploading...',
      [NOTIFICATION_VARIANTS.SUCCESS]:
        'The 3D Object was successfully uploaded',
      [NOTIFICATION_VARIANTS.ERROR]: 'Failed to upload 3D Object',
    },
    payload,
  });

  return payload;
};

export const deleteTool3DObject = (toolImageId) => (dispatch) => {
  const payload = toolService.deleteTool3DObject(toolImageId);

  dispatch({
    type: EQUIPMENT_ACTIONS.DELETE_3D_OBJECT,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Deleting 3D Object...',
      [NOTIFICATION_VARIANTS.SUCCESS]: '3D Object successfully deleted!',
    },
    payload,
  });

  return payload;
};

export const createAssetHistory =
  (asset, equipmentType, getAssetAfterRefresh = true) =>
  (dispatch) => {
    const {
      externalToolId,
      serialNumber,
      typeOfEvent,
      testType,
      taskId,
      drumItemNumber,
      drumSerialNumber,
      storageType,
    } = asset;

    const handleAssetHistoryResponse = () => {
      dispatch(
        getEquipmentAssetHistory(externalToolId, equipmentType, serialNumber),
      );
      if (equipmentType === ASSET_HISTORY_EQUIPMENT_TYPE.CABLE) {
        dispatch(getCableTestDetails(externalToolId, serialNumber));
      }
    };

    const closeModal = () => {
      dispatch(
        toggleModal({ modalId: MODAL.ADD_ASSET_HISTORY_TEST, value: false }),
      );
      dispatch(
        toggleModal({ modalId: MODAL.ADD_ASSET_HISTORY_SURFACE, value: false }),
      );
      dispatch(
        toggleModal({ modalId: MODAL.ADD_ASSET_HISTORY_BHA, value: false }),
      );
      dispatch(
        toggleModal({
          modalId: MODAL.ADD_ASSET_HISTORY_SURFACE_SIL2,
          value: false,
        }),
      );
    };

    let assetHistoryService;
    let successMessage = 'Asset history successfully created!';

    switch (typeOfEvent) {
      case String(ASSET_HISTORY_EVENT_TYPES_ITEM.TEST):
        switch (testType) {
          case String(ASSET_HISTORY_TEST_TYPE.BOP_TEST):
            assetHistoryService = equipmentService.createBopTestAssetHistory;
            break;
          case String(ASSET_HISTORY_TEST_TYPE.STRING_TEST):
            assetHistoryService = equipmentService.createStringTestAssetHistory;
            successMessage = 'String verification successfully created!';
            break;
          case String(ASSET_HISTORY_TEST_TYPE.CABLE_TORSION_TEST):
            assetHistoryService =
              equipmentService.createcableTorsionTestAssetHistory;
            break;
          case String(ASSET_HISTORY_TEST_TYPE.CABLE_WRAP_TEST):
            assetHistoryService =
              equipmentService.createcableWrapTestAssetHistory;
            break;
          case String(ASSET_HISTORY_TEST_TYPE.CABLE_TORTURE_TEST):
            assetHistoryService =
              equipmentService.createcableTortureTestAssetHistory;
            break;
          case String(ASSET_HISTORY_TEST_TYPE.CABLE_CONTINUITY_TEST):
            assetHistoryService =
              equipmentService.createcableContinuityTestAssetHistory;
            break;
          case String(ASSET_HISTORY_TEST_TYPE.CABLE_INSULATION_TEST):
            assetHistoryService =
              equipmentService.createcableInsulationTestAssetHistory;
            break;
          case String(ASSET_HISTORY_TEST_TYPE.SIL2_TEST):
            assetHistoryService = equipmentService.createSil2TestAssetHistory;
            break;
          case String(ASSET_HISTORY_TEST_TYPE.CABLE_WRAP_BRAIDED_MONO_TEST):
            assetHistoryService =
              equipmentService.createCableWrapBraidedMonoTestAssetHistory;
            break;
          case String(ASSET_HISTORY_TEST_TYPE.CABLE_BREAK_TEST):
            assetHistoryService =
              equipmentService.createCableBreakTestAssetHistory;
            break;
          default:
            assetHistoryService = equipmentService.createTestAssetHistory;
            break;
        }
        break;
      case String(ASSET_HISTORY_EVENT_TYPES_ITEM.MAINTENANCE):
        switch (testType) {
          case String(ASSET_HISTORY_MAINTENANCE_TYPE.GENERIC):
            assetHistoryService =
              equipmentService.createMaintenanceAssetHistory;
            break;
          case String(ASSET_HISTORY_MAINTENANCE_TYPE.CABLE_CUT):
            assetHistoryService =
              equipmentService.createCableCutMaintenanceAssetHistory;
            break;
          case String(ASSET_HISTORY_MAINTENANCE_TYPE.CABLE_SERVICE):
            assetHistoryService =
              equipmentService.createCableServiceMaintenanceAssetHistory;
            break;
          case String(ASSET_HISTORY_MAINTENANCE_TYPE.CABLE_SPOOLING):
            assetHistoryService =
              equipmentService.createCableSpoolingMaintenanceAssetHistory;
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }

    dispatch({
      type: EQUIPMENT_ACTIONS.CREATE_ASSET_HISTORY,
      notification: {
        [NOTIFICATION_VARIANTS.INFO]: 'Creating asset history...',
      },
      payload: () =>
        assetHistoryService(
          asset,
          externalToolId,
          equipmentType,
          serialNumber,
          taskId ?? 0,
          drumItemNumber === '' ? 0 : drumItemNumber ?? 0,
          drumSerialNumber === '' ? 0 : drumSerialNumber ?? 0,
          storageType,
        )
          .then(() => {
            try {
              dispatch(
                addNotification({
                  message: successMessage,
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                }),
              );

              if (getAssetAfterRefresh) {
                return handleAssetHistoryResponse();
              }
            } finally {
              closeModal();
            }
          })
          .catch((error) => {
            closeModal();
            const message = error?.response?.body?.hasErrors
              ? error.response.body.errors[0]
              : 'Error creating the asset history.';

            dispatch(
              addNotification({
                message,
                variant: NOTIFICATION_VARIANTS.ERROR,
              }),
            );
          }),
    });
  };

export const validateToolStringSerialNumbers = (toolstringId) => (dispatch) => {
  const openModal = (show) => {
    dispatch(
      toggleModal({ modalId: MODAL.ADD_ASSET_HISTORY_BHA, value: show }),
    );
  };

  dispatch({
    type: EQUIPMENT_ACTIONS.VALIDATE_TOOLSTRING_SERIAL_NUMBERS,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Validating serial numbers...',
    },
    payload: () =>
      equipmentService
        .validateToolStringSerialNumbers(toolstringId)
        .then(() => {
          openModal(true);
        })
        .catch((error) => {
          openModal(false);
          const message = error?.response?.body?.hasErrors
            ? error.response.body.errors[0]
            : 'Error has ocurred, please try again.';

          dispatch(
            addNotification({
              message,
              variant: NOTIFICATION_VARIANTS.ERROR,
            }),
          );
        }),
  });
};

export const validateSurfaceEquipmentSerialNumbers = (taskId) => (dispatch) => {
  dispatch(getEquipmentByCategories(taskId));
  const openModal = (show) => {
    dispatch(
      toggleModal({
        modalId: MODAL.ADD_ASSET_HISTORY_SURFACE_SIL2,
        value: show,
      }),
    );
  };

  dispatch({
    type: EQUIPMENT_ACTIONS.VALIDATE_TOOLSTRING_SERIAL_NUMBERS,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Validating serial numbers...',
    },
    payload: () =>
      equipmentService
        .validateSurfaceEquipmentSerialNumbers(taskId)
        .then(() => {
          openModal(true);
        })
        .catch((error) => {
          openModal(false);
          const message = error?.response?.body?.hasErrors
            ? error.response.body.errors[0]
            : 'Error has ocurred, please try again.';

          dispatch(
            addNotification({
              message,
              variant: NOTIFICATION_VARIANTS.ERROR,
            }),
          );
        }),
  });
};

export const getBhaTemplateForEditing = (templateId) => {
  return {
    templateId,
    type: EQUIPMENT_ACTIONS.REQUEST_BHA_TEMPLATE_FOR_EDITING,
  };
};

export const receiveBhaTemplateForEditing = (template) => ({
  payload: template,
  type: EQUIPMENT_ACTIONS.RECEIVE_BHA_TEMPLATE_FOR_EDITING,
});

export const getBhaTemplateSectionsForEditing = (templateId) => {
  return {
    templateId,
    type: EQUIPMENT_ACTIONS.REQUEST_BHA_TEMPLATE_SECTIONS_FOR_EDITING,
  };
};

export const receiveBhaTemplateSectionsForEditing = (sections) => ({
  payload: sections,
  type: EQUIPMENT_ACTIONS.RECEIVE_BHA_TEMPLATE_SECTIONS_FOR_EDITING,
});

export const unloadBhaTemplateForEditing = () => (dispatch) => {
  dispatch({
    type: EQUIPMENT_ACTIONS.UNLOAD_BHA_TEMPLATE_FOR_EDITING,
  });
};

export const addTemplateTool = (toolstringId, tool) => {
  const toolName = tool.get('name');

  return {
    toolstringId,
    tool,
    type: EQUIPMENT_ACTIONS.CREATE_TEMPLATE_TOOL,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Loading...',
      [NOTIFICATION_VARIANTS.SUCCESS]: `${toolName} was successfully added to BHA`,
    },
  };
};

export const deleteTemplateTool = (toolstringItemId, templateId) => ({
  type: EQUIPMENT_ACTIONS.DELETE_TEMPLATE_TOOL,
  toolstringItemId,
  templateId,
  confirmationDialog: {
    title: 'Remove tool',
    description: 'Are you sure you want to remove this tool?',
    confirmButtonText: 'Remove',
  },
  notification: {
    [NOTIFICATION_VARIANTS.INFO]: 'Updating BHA...',
    [NOTIFICATION_VARIANTS.SUCCESS]: 'The tool was successfully removed',
  },
});

export const updateTemplate = (templateId, toolstring) => ({
  templateId,
  toolstring,
  type: EQUIPMENT_ACTIONS.REQUEST_UPDATE_TEMPLATE_FOR_EDITING,
  notification: {
    [NOTIFICATION_VARIANTS.INFO]: 'Updating BHA...',
    [NOTIFICATION_VARIANTS.SUCCESS]: 'BHA updated succesfully',
  },
});

export const duplicateTemplateItem = (templateId, tool) => {
  const toolName = tool.get('name');

  return {
    templateId,
    tool,
    type: EQUIPMENT_ACTIONS.REQUEST_DUPLICATE_TEMPLATE_ITEM,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Loading...',
      [NOTIFICATION_VARIANTS.SUCCESS]: `${toolName} was successfully duplicated`,
    },
  };
};

export const sortTemplateTools = (templateId, sortedTool, affectedTool) => ({
  templateId,
  sortedTool,
  affectedTool,
  type: EQUIPMENT_ACTIONS.REQUEST_SORT_TEMPLATE_TOOLS,
  notification: {
    [NOTIFICATION_VARIANTS.INFO]: 'Moving tool...',
    [NOTIFICATION_VARIANTS.SUCCESS]: 'BHA sequence successfully updated!',
  },
});

export const sortTemplateAssemblyTools = (
  templateId,
  toolstringItemId,
  sortedToolstringAssemblyTool,
  affectedToolstringAssemblyTool,
) => ({
  templateId,
  toolstringItemId,
  sortedToolstringAssemblyTool,
  affectedToolstringAssemblyTool,
  type: EQUIPMENT_ACTIONS.REQUEST_SORT_TEMPLATE_ASSEMBLY_TOOLS,
  notification: {
    [NOTIFICATION_VARIANTS.INFO]: 'Moving tool...',
    [NOTIFICATION_VARIANTS.SUCCESS]: 'Tool sequence successfully updated!',
  },
});

export const editToolImage = (values, imageId) => (dispatch) => {
  dispatch({
    type: EQUIPMENT_ACTIONS.EDIT_TOOL_IMAGE,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Saving...',
      [NOTIFICATION_VARIANTS.SUCCESS]: 'The image was successfully saved',
      [NOTIFICATION_VARIANTS.ERROR]: 'Failed to save image',
    },
    payload: toolService.editToolImage(values, imageId).then(() => {
      dispatch(toggleModal(MODAL.EDIT_TOOL_IMAGE));
      window.location.reload();
    }),
  });
};

export const getEquipmentAssetHistory =
  (externalToolId, equipmentType, serialNumber) => (dispatch) => {
    dispatch({
      type: EQUIPMENT_ACTIONS.LOAD_ASSET_HISTORY,
      payload: () =>
        equipmentService.getAssetHistoryByExternalToolId(
          externalToolId,
          equipmentType,
          serialNumber,
        ),
    });
  };

export const loadEquipmentAssets =
  (equipmentId, equipmentType) => (dispatch) => {
    dispatch({
      type: EQUIPMENT_ACTIONS.RECEIVE_EQUIPMENT_ASSETS,
      payload: () =>
        equipmentService.getEquipmentAssets(equipmentId, equipmentType),
    });
  };

export const deleteTemplateToolAssemblyTool = (
  toolAssemblyToolId,
  templateId,
) => ({
  type: EQUIPMENT_ACTIONS.DELETE_TEMPLATE_TOOLASSEMBLY_TOOL,
  toolAssemblyToolId,
  templateId,
  confirmationDialog: {
    title: 'Remove tool',
    description: 'Are you sure you want to remove this tool?',
    confirmButtonText: 'Remove',
  },
  notification: {
    [NOTIFICATION_VARIANTS.INFO]: 'Updating BHA...',
    [NOTIFICATION_VARIANTS.SUCCESS]: 'The tool was successfully removed',
  },
});

export const validateToolStringSerialNumbersForMobile =
  (toolstringId) => (dispatch) => {
    dispatch(getEquipmentByCategories());

    const openErrorModal = (show) => {
      dispatch(
        toggleModal({ modalId: MODAL.MISSING_SERIAL_NUMBER, value: show }),
      );
    };
    const openVerificationModal = (show) => {
      dispatch(
        toggleModal({ modalId: MODAL.ADD_ASSET_HISTORY_BHA, value: show }),
      );
    };

    dispatch({
      type: EQUIPMENT_ACTIONS.VALIDATE_TOOLSTRING_SERIAL_NUMBERS,
      notification: {
        [NOTIFICATION_VARIANTS.INFO]: 'Validating serial numbers...',
      },
      payload: () =>
        equipmentService
          .validateToolStringSerialNumbers(toolstringId)
          .then(() => {
            openVerificationModal(true);
          })
          .catch(() => {
            openErrorModal(true);
          }),
    });
  };

export const getStringVerificationsForToolstring =
  (taskId, toolstringId) => (dispatch) => {
    const payload = equipmentService.getStringVerificationsForToolstring(
      taskId,
      toolstringId,
    );
    dispatch({
      type: EQUIPMENT_ACTIONS.RECEIVE_STRING_VERIFICATIONS_FOR_TOOLSTRING,
      payload: payload,
    });

    return payload;
  };

export const getCableTestDetails = (cableId, serialNumber) => (dispatch) => {
  const payload = equipmentService.getCableTestDetails(cableId, serialNumber);
  dispatch({
    type: EQUIPMENT_ACTIONS.RECEIVE_CABLE_TEST_DETAILS,
    payload: payload,
  });

  return payload;
};

export const exportCableHistory = (equipmentId, serialNumber) => (dispatch) => {
  const payload = equipmentService.exportCableHistory(
    equipmentId,
    serialNumber,
  );
  dispatch({
    type: EQUIPMENT_ACTIONS.EXPORT_CABLE_HISTORY,
    payload: payload,
  });

  dispatch({
    type: EQUIPMENT_ACTIONS.EXPORT_CABLE_HISTORY,
    payload: payload,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Exporting Cable History...',
      [NOTIFICATION_VARIANTS.SUCCESS]: 'Cable History Export Successful!',
    },
  });

  return payload;
};
