import React from 'react';
import {
  INTEGRITY_MANAGEMENT_RULE_TYPES,
  RuleForm as FormFields,
  IntegrityRulesYupValidator,
  RULE_TYPES_ENUM,
} from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Button, InputAdornment } from '@material-ui/core';
import { LoadingButton } from 'altus-ui-components';
import { Form, Formik, Field } from 'formik';
import SelectTextFieldFormik from 'app/components/form/formik/SelectTextFieldFormik';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import { useDispatch } from 'react-redux';
import { addNewIntegrityManagementRule } from 'features/settings/integrityManagement/helpers/integrityManagement.actions';
import DefaultRuleValueWrapper from 'features/settings/integrityManagement/rules/DefaultRuleValueWrapper';
import moment from 'moment';

const AddRuleDrawerContent = ({
  classes,
  integrityManagementRuleTypes = INTEGRITY_MANAGEMENT_RULE_TYPES,
  toggleDrawer,
}) => {
  const getId = (item) => item.id;
  const getName = (item) => item.name;
  const dispatch = useDispatch();

  const initialFormValues = {
    [FormFields.TITLE]: '',
    [FormFields.WEIGHT]: null,
    [FormFields.SCORE]: null,
    [FormFields.USER_ID]: uuidv4(),
    [FormFields.RULE_TYPE]: '',
    [FormFields.DEFAULT_VALUE_CHECKED]: true,
    [FormFields.VALUE]: null,
  };

  const handleSubmit = (formValues) => {
    if (
      Number(formValues[FormFields.RULE_TYPE]) ===
        RULE_TYPES_ENUM.RULE_GEOGRAPHY &&
      formValues[FormFields.DEFAULT_VALUE_CHECKED]
    ) {
      formValues[FormFields.VALUE] = {
        latitude: formValues[FormFields.LATITUDE],
        longitude: formValues[FormFields.LONGITUDE],
      };
    } else if (
      Number(formValues[FormFields.RULE_TYPE]) === RULE_TYPES_ENUM.RULE_TIME &&
      formValues[FormFields.DEFAULT_VALUE_CHECKED]
    ) {
      formValues[FormFields.VALUE] = moment(
        formValues[FormFields.VALUE],
      ).toISOString();
    }
    dispatch(addNewIntegrityManagementRule(formValues, toggleDrawer));
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={initialFormValues}
      validationSchema={IntegrityRulesYupValidator}
      onSubmit={handleSubmit}
    >
      {(formik) => {
        const { isValid, isSubmitting } = formik;
        return (
          <Form>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <SelectTextFieldFormik
                      getItemName={getName}
                      placeholder="Select"
                      label="Rule Type"
                      getItemValue={getId}
                      items={integrityManagementRuleTypes}
                      name={FormFields.RULE_TYPE}
                      displayEmpty={false}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} container>
                    <Field
                      type="string"
                      label="Rule"
                      component={TextFieldFormik}
                      name={FormFields.TITLE}
                      placeholder=""
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6} container>
                    <Field
                      type="number"
                      label="Weighting of Rule"
                      component={TextFieldFormik}
                      name={FormFields.WEIGHT}
                      placeholder=""
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} container>
                    <Field
                      type="number"
                      label="Score"
                      component={TextFieldFormik}
                      name={FormFields.SCORE}
                      placeholder=""
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DefaultRuleValueWrapper />
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.gridBackground}>
              <Button size="large" color="default" onClick={toggleDrawer}>
                CANCEL
              </Button>
              <LoadingButton
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                loading={isSubmitting}
                form={FormFields.ID}
                disabled={!isValid || isSubmitting}
              >
                SAVE
              </LoadingButton>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

const styles = (theme) => ({
  triggerIconRoot: {
    height: 45,
    width: 45,
    marginRight: 10,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
    },
  },
  gridBackground: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    width: '100%',
  },
  gridBackgroundMobile: {
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  backButtonIcon: {
    color: theme.palette.primary.newLight,
    width: '90%',
    height: '90%',
  },
  statusHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  containedButton: {
    minHeight: theme.spacing(8),
    minWidth: theme.spacing(20),
    marginBottom: theme.spacing(2),
  },
  containedButtonGrey: {
    minHeight: theme.spacing(8),
    minWidth: theme.spacing(20),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.secondary.lighter,
    color: theme.palette.secondary.dark,
  },
});

export default withStyles(styles)(AddRuleDrawerContent);

//temporary function to generate uuid for userId
function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      // eslint-disable-next-line
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
