import { useMemo } from 'react';
import { Table } from 'altus-ui-components';
import { Grid, Typography, IconButton } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import DeleteIcon from '@material-ui/icons/Delete';
import { EMPTY_LIST } from 'app/app.constants';
import { getRuleTypeString } from 'features/settings/integrityManagement/helpers/integrityManagement.constants';

const RulesTable = ({ rules, deleteRule }) => {
  const columns = useMemo(
    () => [
      {
        xs: 3,
        Header: 'Name',
        accessor: (rule) => rule.title,
      },
      {
        xs: 2,
        Header: 'Type',
        accessor: (rule) => rule.type,
        Cell: ({ row }) => {
          const rule = row.original;
          return (
            <Grid item xs={2}>
              <Typography variant="body2">
                {getRuleTypeString(rule.ruleType) || '-'}
              </Typography>
            </Grid>
          );
        },
      },
      {
        xs: 2,
        Header: 'Score',
        accessor: (rule) => rule.score,
        Cell: ({ row }) => {
          const rule = row.original;
          return (
            <Grid item xs={2}>
              <Typography variant="body2">{rule.score || '-'}</Typography>
            </Grid>
          );
        },
      },
      {
        xs: 2,
        Header: 'Weight',
        accessor: (rule) => rule.weight,
        Cell: ({ row }) => {
          const rule = row.original;
          return (
            <Grid item xs={2}>
              <Typography variant="body2">
                {`${rule.weight}%` || '-'}
              </Typography>
            </Grid>
          );
        },
      },
      {
        xs: 2,
        Header: 'Impact',
        accessor: (rule) => rule.impact,
        Cell: ({ row }) => {
          const rule = row.original;
          return (
            <Grid item xs={2}>
              <Typography variant="body2">{rule.impact || '-'}</Typography>
            </Grid>
          );
        },
      },
      {
        xs: 1,
        id: 'delete',
        Cell: ({ row }) => {
          const rule = row.original;

          return (
            <Grid item>
              <IconButton
                color="default"
                onClick={() => deleteRule(rule.ruleId)}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          );
        },
      },
    ],
    [deleteRule],
  );

  return (
    <Table
      items={rules || EMPTY_LIST}
      columns={columns}
      useGlobalFilter={true}
      noItemsMessage="There are no rules for this organization."
    />
  );
};

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.grey[700],
  },
});

export default withStyles(styles)(RulesTable);
