import { Map, List } from 'immutable';

import { EMPTY_LIST } from 'app/app.constants';
import { INTEGRITY_MANAGEMENT_ACTIONS } from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import integrityManagementMappers from 'features/settings/integrityManagement/helpers/integrityManagement.mappers';

const initialState = Map({
  rules: EMPTY_LIST,
});

const integrityManagementReducer = (state = initialState, action = {}) => {
  const { type } = action;

  switch (type) {
    case INTEGRITY_MANAGEMENT_ACTIONS.GET_ALL_INTEGRITY_MANAGEMENT_RULES:
    case INTEGRITY_MANAGEMENT_ACTIONS.DELETE_INTEGRITY_MANAGEMENT_RULE: {
      if (action.error) return state;

      return state.set(
        'rules',
        List(
          action.payload.map((rules) =>
            integrityManagementMappers.rules.from(rules),
          ),
        ),
      );
    }

    default:
      return state;
  }
};

export default integrityManagementReducer;
