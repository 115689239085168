import React from 'react';
import { RuleForm } from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import { Grid } from '@material-ui/core';
import { Field } from 'formik';

const GeographyRuleDataFormComponent = () => {
  return (
    <>
      <Grid item xs={6} container>
        <Field
          type="number"
          label="Latitude"
          component={TextFieldFormik}
          name={RuleForm.LATITUDE}
        />
      </Grid>
      <Grid item xs={6} container>
        <Field
          type="number"
          label="Longitude"
          component={TextFieldFormik}
          name={RuleForm.LONGITUDE}
        />
      </Grid>
    </>
  );
};

export default GeographyRuleDataFormComponent;
