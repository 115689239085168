import CloseIcon from '@material-ui/icons/Close';
import { Grid, IconButton, Typography } from '@material-ui/core';

const AddRuleDrawerHeader = ({ title, toggleDrawer }) => {
  return (
    <Grid container alignItems="center">
      <Grid item xs={11}>
        <Typography variant="h6" noWrap>
          {title}
        </Typography>
      </Grid>
      <Grid container item xs={1} justifyContent="flex-end">
        <IconButton title="Close" onClick={toggleDrawer}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default AddRuleDrawerHeader;
