const integrityRulesFromDTO = ({ ...rules }) => {
  return {
    ...rules,
  };
};

const integrityRulesToDTO = ({ ...rule }) => {
  return {
    ...rule,
  };
};

const integrityManagementMappers = {
  rules: {
    from: integrityRulesFromDTO,
    to: integrityRulesToDTO,
  },
};

export default integrityManagementMappers;
