import { List } from 'immutable';
import * as yup from 'yup';

export const INTEGRITY_MANAGEMENT_ACTIONS = {
  GET_ALL_INTEGRITY_MANAGEMENT_RULES: 'GET_ALL_INTEGRITY_MANAGEMENT_RULES',
  ADD_NEW_INTEGRITY_MANAGEMENT_RULE: 'ADD_NEW_INTEGRITY_MANAGEMENT_RULE',
  DELETE_INTEGRITY_MANAGEMENT_RULE: 'DELETE_INTEGRITY_MANAGEMENT_RULE',
};

export const RULE_TYPES_ENUM = {
  RULE_BOOL: 1,
  RULE_DOUBLE: 2,
  RULE_GEOGRAPHY: 3,
  RULE_TIME: 4,
};

export const BOOLEAN_DATA_VALUES_ENUM = {
  NO: { value: 0, label: 'No' },
  YES: { value: 1, label: 'Yes' },
};

export const INTEGRITY_MANAGEMENT_RULE_TYPES = List([
  { id: '1', name: 'Data Value (Yes/No)' },
  { id: '2', name: 'Data Value (Numeric Value)' },
  { id: '3', name: 'Geography' },
  { id: '4', name: 'Time/Age' },
]);

export const INTEGRITY_MANAGEMENT_RULE_DATA_TYPE = List([
  { id: '1', name: 'Yes/No' },
  { id: '2', name: 'Numeric Value' },
]);

export const getRuleTypeString = (ruleType) => {
  return INTEGRITY_MANAGEMENT_RULE_TYPES.find(
    (rule) => Number(rule.id) === ruleType,
  ).name;
};

export const RuleForm = {
  TITLE: 'title',
  WEIGHT: 'weight',
  SCORE: 'score',
  USER_ID: 'userId',
  RULE_TYPE: 'ruleType',
  DEFAULT_VALUE_CHECKED: 'defaultValueRequired',
  VALUE: 'value',
  LATITUDE: 'latitude',
  LONGITUDE: 'longitude',
};

export const IntegrityRulesYupValidator = yup.object().shape({
  [RuleForm.TITLE]: yup.string().required('Rule Title is required'),
  [RuleForm.WEIGHT]: yup.number().required('Weighting of Rule is required'),
  [RuleForm.SCORE]: yup.number().required('Score is required'),
  // [RuleForm.VALUE]: yup.string().when(RuleForm.RULE_TYPE, {
  //   is: '1',
  //   then: yup.string().required('Default value is required'),
  //   otherwise: yup.string().notRequired(),
  // }),
});
