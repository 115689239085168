import Agent from 'infrastructure/agent';
import integrityManagementMappers from 'features/settings/integrityManagement/helpers/integrityManagement.mappers';

const getIntegrityManagementRules = () => Agent.get('/integritymanagement');

const addNewIntegrityManagementRule = (rule) =>
  Agent.post('/integritymanagement', integrityManagementMappers.rules.to(rule));

const deleteIntegrityManagementRule = (ruleId) =>
  Agent.delete(`/integritymanagement/${ruleId}`);

const integrityManagementService = {
  getIntegrityManagementRules,
  addNewIntegrityManagementRule,
  deleteIntegrityManagementRule,
};

export default integrityManagementService;
