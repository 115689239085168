import { compose } from 'redux';

import _isNil from 'lodash/isNil';
import { Grid, Typography, IconButton } from '@material-ui/core';
import { useEffect, useCallback } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { connect, useDispatch, useSelector } from 'react-redux';
import DoneAll from '@material-ui/icons/DoneAll';

import { useModal } from 'altus-modal';
import { BasePage } from 'altus-ui-components';

import { List } from 'immutable';

import {
  getSimulationsForTaskFromState,
  getSelectedCablesForTaskFromState,
} from 'features/projects/tasks/task/simulation/simulation.selectors';

import {
  onLoadTaskSurfaceEquipment,
  saveCableSerialNumberToTask,
  saveSurfaceEquipmentSerialNumber,
} from 'features/projects/tasks/tasks.actions';

import {
  getTaskById,
  getTaskSurfaceEquipmentFromState,
} from 'features/projects/tasks/tasks.selectors';

import {
  ACTIONS,
  SimulationState,
} from 'features/projects/tasks/task/simulation/simulation.constants';

import { EMPTY_LIST, ProjectPermission } from 'app/app.constants';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import withProjectPermission from 'app/components/withProjectPermission';
import * as actions from 'features/projects/tasks/task/simulation/simulation.actions';
import { TASK_SURFACE_EQUIPMENT_ACTIONS } from 'features/projects/tasks/tasks.constants';
import AddedCablesInExecuteTable from 'features/projects/tasks/task/surfaceEquipment/components/cables/AddedCablesInExecuteTable';
import AddedSurfaceEquipmentInExecuteTable from 'features/projects/tasks/task/surfaceEquipment/components/surfaceEquipment/AddedSurfaceEquipmentInExecuteTable';

import {
  ASSET_HISTORY_EVENT_TYPES,
  ASSET_HISTORY_EVENT_TYPES_ITEM,
  ASSET_HISTORY_TEST_TYPE,
  ASSET_HISTORY_TEST_TYPES,
  EQUIPMENT_CATEGORY_ENUM,
  EquipmentType,
  MODAL,
} from 'features/equipment/equipment.constants';
import CreateAssetHistoryModalContainer from 'features/equipment/assets/components/CreateAssetHistoryModalContainer';
import { validateSurfaceEquipmentSerialNumbers } from 'features/equipment/equipment.actions';
import { getSurfaceEquipmentCategoriesFromState } from 'features/equipment/equipment.selectors';

const ExecutionSurfaceEquipmentContainer = ({
  taskId,
  classes,
  projectId,
  dataState,
  dispatchOnLoad,
  surfaceEquipmentFromState,
  surfaceEquipmentCategoriesFromState,
}) => {
  const dispatch = useDispatch();
  const [isOpen, toggleModal] = useModal(MODAL.ADD_ASSET_HISTORY_SURFACE_SIL2);
  const openModal = (event) => {
    event?.preventDefault();
    toggleModal();
  };

  const validateSil2SurfaceEquipmentSerialNumbers = () => {
    dispatch(validateSurfaceEquipmentSerialNumbers(taskId));
  };

  const task = useSelector((state) => getTaskById(taskId)(state));

  const testTypesItems = List([
    ASSET_HISTORY_TEST_TYPES.find(
      (item) => item.id === String(ASSET_HISTORY_TEST_TYPE.SIL2_TEST),
    ),
  ]).filter((item) => item);

  const assetHistoryEventTypes = List([
    ASSET_HISTORY_EVENT_TYPES.find(
      (item) => item.id === String(ASSET_HISTORY_EVENT_TYPES_ITEM.TEST),
    ),
  ]).filter((item) => item);

  const surfaceEquipmentCategories = surfaceEquipmentFromState.filter(
    (item) => !!item.get('serialNo'),
  );

  const sil2TestBopItems = surfaceEquipmentCategories.filter(
    (item) => item.get('equipmentCategory') === EQUIPMENT_CATEGORY_ENUM.BOP,
  );

  const sil2TestBcsItems = surfaceEquipmentCategories.filter(
    (item) => item.get('equipmentCategory') === EQUIPMENT_CATEGORY_ENUM.BCS,
  );

  const sil2TestCabinItems = surfaceEquipmentCategories.filter(
    (item) => item.get('equipmentCategory') === 2,
  );

  useEffect(() => {
    dispatchOnLoad(projectId, taskId);
  }, [taskId, dispatchOnLoad, projectId]);

  useEffect(() => {
    if (projectId) {
      dispatch(
        actions.loadSimulations(projectId, taskId, [
          SimulationState.PLANNED,
          SimulationState.UPDATED_PLANNED,
        ]),
      );
    }
  }, [projectId, taskId, dispatch]);

  const simulations = useSelector((state) =>
    !_isNil(taskId)
      ? getSimulationsForTaskFromState(state, taskId)?.valueSeq()
      : EMPTY_LIST,
  );

  const selectedCables = useSelector((state) =>
    getSelectedCablesForTaskFromState(state, taskId),
  );

  const onSubmitCable = useCallback(
    (values) => {
      dispatch(saveCableSerialNumberToTask(projectId, taskId, values.serialNo));
    },
    [dispatch, projectId, taskId],
  );

  const onSubmitSurfaceEquipment = useCallback(
    (values) => {
      dispatch(
        saveSurfaceEquipmentSerialNumber(
          projectId,
          taskId,
          values.serialNo,
          values.taskSurfaceId,
        ),
      );
    },
    [dispatch, projectId, taskId],
  );

  const cablesWithSerialNo = selectedCables?.update(0, (cable) =>
    cable.set('serialNo', task?.get('individualSerialNumber')),
  );

  return (
    <>
      <CreateAssetHistoryModalContainer
        toggleModal={openModal}
        isOpen={isOpen}
        equipmentType={EquipmentType.SURFACE_EQUIPMENT}
        getAssetAfterRefresh={false}
        assetHistoryEventTypes={assetHistoryEventTypes}
        testTypesItems={testTypesItems}
        taskId={taskId}
        defaultEventValue={String(ASSET_HISTORY_EVENT_TYPES_ITEM.TEST)}
        defaultTestTypeValue={String(ASSET_HISTORY_TEST_TYPE.SIL2_TEST)}
        modalTitle="SIL2 Test"
        sil2TestBopItems={sil2TestBopItems}
        sil2TestBcsItems={sil2TestBcsItems}
        sil2TestCabinItems={sil2TestCabinItems}
      />
      <BasePage
        dataState={dataState}
        classes={{
          children: classes.basePageChildren,
        }}
      >
        <Grid container spacing={6} className={classes.container}>
          <Grid item container xs={11} className={classes.equipmentContainer}>
            <Grid item container xs={12} justifyContent="space-between">
              <Typography variant="h5">Cables</Typography>
            </Grid>
            {!simulations || !task || !cablesWithSerialNo ? (
              <Grid item container xs={12} className={classes.emptyEquipment}>
                <Typography variant="caption" color="textSecondary">
                  No simulations planned.
                </Typography>
              </Grid>
            ) : (
              <AddedCablesInExecuteTable
                task={task}
                onSubmit={onSubmitCable}
                cablesWithSerialNo={cablesWithSerialNo}
              />
            )}
          </Grid>
          <Grid item container xs={11} className={classes.equipmentContainer}>
            <Grid item container xs={12} justifyContent="space-between">
              <Typography variant="h5">Surface Equipment</Typography>

              <IconButton
                title="Add Sil2 Test"
                onClick={() => validateSil2SurfaceEquipmentSerialNumbers()}
              >
                <DoneAll />
              </IconButton>
            </Grid>
            {!surfaceEquipmentFromState.size ? (
              <Grid item container xs={12} className={classes.emptyEquipment}>
                <Typography variant="caption" color="textSecondary">
                  No surface equipment added.
                </Typography>
              </Grid>
            ) : (
              <AddedSurfaceEquipmentInExecuteTable
                task={task}
                onSubmitSurfaceEquipment={onSubmitSurfaceEquipment}
                selectedSurfaceEquipment={surfaceEquipmentFromState}
                surfaceEquipmentCategories={surfaceEquipmentCategories}
              />
            )}
          </Grid>
        </Grid>
      </BasePage>
    </>
  );
};

const styles = (theme) => ({
  basePageChildren: {
    paddingTop: theme.spacing(2.25),
  },
  leftIcon: {
    marginRight: 10,
  },
  emptyEquipment: {
    border: `thin solid ${theme.palette.grey[700]}`,
    justifyContent: 'center',
    alignItems: 'center',
    height: '15vh',
  },
  equipmentContainer: {
    gap: theme.spacing(2.25),
  },
});

const mapStateToProps = (state) => ({
  surfaceEquipmentFromState: getTaskSurfaceEquipmentFromState(state),
  surfaceEquipmentCategoriesFromState:
    getSurfaceEquipmentCategoriesFromState(state),
  dataState: getSummarizedDataStateFromState(
    state,
    ACTIONS.LOAD_SIMULATIONS,
    TASK_SURFACE_EQUIPMENT_ACTIONS.PAGE_LOADED,
    TASK_SURFACE_EQUIPMENT_ACTIONS.CABLES_LOADED,
    TASK_SURFACE_EQUIPMENT_ACTIONS.SAVE_CABLE_SERIAL_NUMBER,
  ),
});

const mapDispatchToProps = {
  dispatchOnLoad: onLoadTaskSurfaceEquipment,
};

export default withProjectPermission(ProjectPermission.EXECUTE)(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
  )(ExecutionSurfaceEquipmentContainer),
);
