import { put, call, takeEvery } from 'redux-saga/effects';

import { toggleModal } from 'altus-modal';
import { callAsync } from 'app/sagas/helperSagas';
import { addNotification } from 'altus-redux-middlewares';

import {
  ACTIONS,
  MODAL,
} from 'features/projects/mobilisation/projectMobilisation.constants';
import projectMobilisationService from 'features/projects/mobilisation/projectMobilisation.service';
import {
  receiveProjectMobilisationTasks,
  receiveStringTestReportEvent,
  receiveStringTestReportsForTask,
  receiveToolstringItemCopies,
} from 'features/projects/mobilisation/projectMobilisation.actions';
import equipmentService from 'features/equipment/equipment.service';
import { EMPTY_STRING, NOTIFICATION_VARIANTS } from 'app/app.constants';
import { requestToolstringForTaskAsync } from '../tasks/task/toolstring/toolstring.sagas';

export function* requestMobilisationTasks(action) {
  const { projectId } = action;

  const projectMobilisationTasks = yield call(
    projectMobilisationService.getProjectMobilisationTasks,
    projectId,
  );

  yield call(
    projectMobilisationService.getProjectMobilisationToolstrings,
    projectId,
  );

  yield put(receiveProjectMobilisationTasks(projectMobilisationTasks));
}

export function* createStringTestReport(action) {
  const { taskId, stringTestReport, toggleModal } = action;

  const { toolstringId } = stringTestReport;

  const asset = {
    toolstringId,
    taskId,
    ...stringTestReport,
  };

  yield call(equipmentService.createStringTestAssetHistory, asset);

  const stringTestReports = yield call(
    equipmentService.getStringVerificationsForToolstring,
    taskId,
    toolstringId,
    true,
  );

  yield put(receiveStringTestReportsForTask(stringTestReports));

  if (toggleModal) {
    yield put(toggleModal({ modalId: MODAL.STRING_TEST_REPORT_EVENT }));
  }
}

export function* requestStringTestReportsForTask(action) {
  const { taskId, toolstringId } = action;

  const stringTestReports = yield call(
    equipmentService.getStringVerificationsForToolstring,
    taskId,
    toolstringId,
    true,
  );

  yield put(receiveStringTestReportsForTask(stringTestReports));
}

export function* requestStringTestReportEventById(action) {
  const { projectId, taskId, stringTestReportEventId } = action;

  const event = yield call(
    projectMobilisationService.getStringTestReportEventById,
    projectId,
    taskId,
    stringTestReportEventId,
  );

  yield put(receiveStringTestReportEvent(event));
}

export function* createSecondaryStringTestReport(action) {
  const { projectId, taskId, toolstringId } = action;

  yield call(
    projectMobilisationService.createSecondaryStringTestReport,
    projectId,
    taskId,
    toolstringId,
  );

  const stringTestReports = yield call(
    projectMobilisationService.getStringTestReportsForTask,
    projectId,
    taskId,
  );

  yield put(receiveStringTestReportsForTask(stringTestReports));
}

export function* finishStringTestReport(action) {
  const { updateDraftReport, taskId, toolstringId } = action;

  yield call(equipmentService.updateStringTestDraft, updateDraftReport);

  const stringTestReports = yield call(
    equipmentService.getStringVerificationsForToolstring,
    taskId,
    toolstringId,
    true,
  );

  yield put(receiveStringTestReportsForTask(stringTestReports));

  yield put(toggleModal({ modalId: MODAL.STRING_TEST_REPORT_EVENT }));
}

export function* updateToolstringItemCopy(action) {
  const { taskId, toolstringId, projectId, asset } = action;

  const assetDto = {
    toolstringItemId: asset.toolstringItemId,
    toolAssemblyId: asset.toolAssemblyId,
    toolAssemblyToolId: asset.toolAssemblyToolId,
    serialNumber: asset.serialNo === EMPTY_STRING ? null : asset.serialNo,
  };

  yield call(
    equipmentService.createToolstringItemCopy,
    taskId,
    toolstringId,
    assetDto,
  );

  yield call(requestToolstringForTaskAsync, {
    projectId,
    taskId,
    toolstringId,
    isMobilise: true,
  });
}

export function* getToolstringItemCopiesForToolstring(action) {
  const { taskId, toolstringId } = action;

  const toolstringItemCopies = yield call(
    equipmentService.getToolstringItemCopiesForToolstring,
    taskId,
    toolstringId,
  );

  yield put(receiveToolstringItemCopies(toolstringItemCopies));
}

export function* validateToolstringItemCopiesSerials(action) {
  const { toolstringItemCopyIds } = action;

  const dto = {
    ToolstringItemCopyIds: toolstringItemCopyIds,
  };

  try {
    yield call(equipmentService.validateToolstringItemCopySerial, dto);

    yield put(toggleModal({ modalId: MODAL.STRING_TEST_REPORT_EVENT }));
  } catch (error) {
    yield put(
      addNotification({
        message: 'Duplicate serial numbers found',
        duration: 5000,
        variant: NOTIFICATION_VARIANTS.ERROR,
      }),
    );
  }
}

export default function* root() {
  yield takeEvery(
    ACTIONS.VALIDATE_TOOLSTRINGITEMCOPIES_SERIALS,
    callAsync,
    validateToolstringItemCopiesSerials,
  );

  yield takeEvery(
    ACTIONS.REQUEST_GET_TOOLSTRINGITEMCOPIES,
    callAsync,
    getToolstringItemCopiesForToolstring,
  );

  yield takeEvery(
    ACTIONS.REQUEST_UPDATE_TOOLSTRINGITEMCOPY,
    callAsync,
    updateToolstringItemCopy,
  );

  yield takeEvery(
    ACTIONS.REQUEST_PROJECT_MOBILISATION_TASKS,
    callAsync,
    requestMobilisationTasks,
  );

  yield takeEvery(
    ACTIONS.CREATE_STRING_TEST_REPORT,
    callAsync,
    createStringTestReport,
  );

  yield takeEvery(
    ACTIONS.REQUEST_STRING_TEST_REPORTS_FOR_TASK,
    callAsync,
    requestStringTestReportsForTask,
  );

  yield takeEvery(
    ACTIONS.REQUEST_STRING_TEST_REPORT_EVENT,
    callAsync,
    requestStringTestReportEventById,
  );

  yield takeEvery(
    ACTIONS.REQUEST_CREATE_SECONDARY_TEST_REPORT,
    callAsync,
    createSecondaryStringTestReport,
  );

  yield takeEvery(
    ACTIONS.REQUEST_FINISH_STRING_TEST_REPORT,
    callAsync,
    finishStringTestReport,
  );
}
